<template>
  <div>
    <list-layout
      :can-operate="canOperate"
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :default-selected="tableDefaultSelectData"
      :export-func="exportFunc"
      :get-table-data-func="getTableDataFunc"
      :group-tree-func="groupTreeFunc"
      :in-modal="inModal"
      :refresh="updateTableFlag"
      :customer-params="params"
      :search-type-options="[
        { label: '名称', value: 'name' },
        { label: 'IP 地址', value: 'monitor_address' }
      ]"
      :table-operation-width="tableOperationWidth"
      :tools-list="toolsList"
      @tableSelectData="getSelectData"
      sourceType="security"
      :apiPermission="groupApiPermission"
      :can-drag-column-width="true"
    >
      <template #leftTool>
        <create-button
          v-permission="{
            action: 'base.terminal_device.create',
            effect: 'disabled'
          }"
          @click="$refs.inputDrawer.show()"
        ></create-button>
      </template>
      <!-- 表格自定义列 -->
      <template #name="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text">
          <a
            v-if="link"
            @click="$refs.terminalDrawer.show(slotProps.record.id)"
          >
            {{ slotProps.text }}
          </a>
          <span v-else>{{ slotProps.text }}</span>
        </a-tooltip>
      </template>
      <!-- <template #deviceType="{ slotProps }">
        {{ $t(`terminal_device_type.${slotProps.text}`) }}
      </template> -->
      <template #delay="{ slotProps }">
        {{ `${slotProps.text} 秒` }}
      </template>
      <template #statusTitle>
        <status-question-icon></status-question-icon>
      </template>
      <template #status="{ slotProps }">
        <source-status-tag :status="slotProps.text"></source-status-tag>
      </template>
      <template #operation="{ slotProps }">
        <sync-button @click="sync(slotProps.record.id)"></sync-button>
        <a-dropdown>
          <a-button icon="more" size="small" type="link" style="color: #faad14">
            更多
          </a-button>
          <a-menu slot="overlay">
            <a-menu-item key="edit">
              <edit-button
                v-permission="{
                  action: 'base.terminal_device.update',
                  effect: 'disabled'
                }"
                @click="$refs.inputDrawer.show(slotProps.record.id)"
              ></edit-button>
            </a-menu-item>
            <a-menu-item key="clone">
              <clone-button @click="$refs.inputDrawer.show(slotProps.record.id, true)"></clone-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <delete-button
          v-permission="{
            action: 'base.terminal_device.delete',
            effect: 'disabled'
          }"
          @confirm="confirm(slotProps.record.id)"
        ></delete-button>
      </template>
    </list-layout>

    <terminal-drawer ref="terminalDrawer"></terminal-drawer>
    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import {
  createTerminalDeviceGroup,
  deleteTerminalDevice,
  deleteTerminalDeviceGroup,
  exportTerminalDevice,
  getTerminalDeviceGroup,
  getTerminalDeviceGroupList,
  getTerminalDeviceList,
  syncTerminalDevice,
  updateTerminalDeviceGroup
} from '@/api/terminal-device'
import { hasPermission } from '@/utils'
import { sourceStatuses } from '@/utils/const'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import SyncButton from '@/components/button/SyncButton'
import StatusQuestionIcon from '@/components/icon/StatusQuestionIcon'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import CloneButton from '@/components/button/CloneButton'
import TerminalDrawer from '@/components/drawer/TerminalDrawer/index.vue'
import InputDrawer from './InputDrawer/index.vue'

export default {
  name: 'TerminalTable',
  components: {
    DeleteButton,
    EditButton,
    ListLayout,
    SourceStatusTag,
    StatusQuestionIcon,
    CloneButton,
    InputDrawer,
    CreateButton,
    TerminalDrawer,
    SyncButton
  },
  props: {
    defaultSelected: {
      type: Array
    },
    toolsList: {
      type: Array,
      default: () => []
    },
    tableOperationWidth: {
      type: Number,
      default: 240
    },
    link: {
      type: Boolean,
      default: true
    },
    refresh: {
      type: Boolean
    },
    canOperate: {
      type: Boolean,
      default: true
    },
    inModal: {
      type: Boolean,
      default: false
    },
    isSimple: {
      type: Boolean,
      default: false
    },
    fetchParams: {
      type: Object
    }
  },
  data () {
    return {
      getTableDataFunc: getTerminalDeviceList,
      exportFunc: exportTerminalDevice,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 190,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        // {
        //   dataIndex: 'device_type',
        //   title: '设备类型',
        //   width: 100,
        //   scopedSlots: {
        //     customRender: 'deviceType'
        //   }
        // },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 160,
          scopedSlots: {
            customRender: 'monitorAddress'
          }
        },
        {
          dataIndex: 'delay',
          title: '监控频率',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'delay'
          }
        },
        {
          dataIndex: 'location',
          title: '位置',
          width: 200,
          scopedSlots: {
            customRender: 'location'
          }
        },
        {
          dataIndex: 'status',
          width: 100,
          slots: {
            title: 'statusTitle',
            name: '状态'
          },
          scopedSlots: {
            customRender: 'status'
          },
          filteredValue: [],
          filters: sourceStatuses.map(value => {
            return {
              text: this.$t(`source_status.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        // 'device_type',
        'monitor_address',
        'delay',
        'location',
        'status'
      ],
      updateTableFlag: this.refresh,
      customerParams: this.$route.params,
      tableSelectData: [],
      tableDefaultSelectData: [],
      groupApiPermission: {
        create: 'base.terminal_device_group.create',
        edit: 'base.terminal_device_group.update',
        delete: 'base.terminal_device_group.delete'
      }
    }
  },
  computed: {
    params () {
      const obj = {}
      for (const key in this.customerParams) {
        if (Object.hasOwnProperty.call(this.customerParams, key)) {
          obj[key] = this.customerParams[key]
        }
      }
      for (const key in this.fetchParams) {
        if (Object.hasOwnProperty.call(this.fetchParams, key)) {
          obj[key] = this.fetchParams[key]
        }
      }
      this.columnsAll.forEach(column => {
        if (Object.hasOwnProperty.call(column, 'filteredValue')) {
          if (
            Object.hasOwnProperty.call(obj, column.dataIndex) &&
            ((Array.isArray(this.deviceType) &&
              obj.device_type.toString() !== this.deviceType.toString()) ||
              !Array.isArray(this.deviceType))
          ) {
            column.filteredValue = [obj[column.dataIndex]]
          } else column.filteredValue = []
        }
      })
      return obj
    },
    groupTreeFunc () {
      if (hasPermission(['base.terminal_device_group.view'])) {
        return {
          createFunc: createTerminalDeviceGroup,
          deleteFunc: deleteTerminalDeviceGroup,
          getFunc: getTerminalDeviceGroup,
          getListFunc: getTerminalDeviceGroupList,
          updateFunc: updateTerminalDeviceGroup
        }
      } else return null
    }
  },
  mounted () {
    if (this.defaultSelected) this.tableDefaultSelectData = this.defaultSelected
  },
  methods: {
    getSelectData (data) {
      this.tableSelectData = data
      this.$emit('selectedData', data)
    },
    async confirm (id = '') {
      if (id) {
        await deleteTerminalDevice(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(
          this.tableSelectData.findIndex(item => item.key === id),
          1
        )
        this.tableDefaultSelectData = this.tableSelectData
        this.$emit('selectedData', this.tableSelectData)
      }
      this.updateTableFlag = !this.updateTableFlag
    },
    sync (id) {
      syncTerminalDevice({ id }).then(res => {
        this.$message.success(res.message)
      })
    },
    monitor () {
      this.$refs.inputDrawer.show()
    }
  },
  watch: {
    refresh (v) {
      this.updateTableFlag = v
    }
  }
}
</script>
