<template>
  <div class="file-import-drawer">
    <a-steps direction="vertical" size="small" :current="currentStep">
      <a-step title="文件上传">
        <template slot="description" v-if="currentStep === 0">
          <a-alert
            banner
            type="info"
            style="border-radius: 6px; margin-top: 16px"
          >
            <template slot="message">
              <span>仅支持上传 Excel 格式的文件。点击下载&nbsp;&nbsp;</span>
              <a @click="click">{{ filename }}</a>
              <span>&nbsp;&nbsp;文件。</span>
            </template>
          </a-alert>
          <a-form-model
            ref="form"
            :label-col="labelCol"
            :model="form"
            :rules="rules"
            :wrapper-col="wrapperCol"
            style="margin-top: 16px"
          >
            <a-form-model-item prop="fileList" label="上传文件">
              <a-upload-dragger
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :before-upload="beforeUpload"
                @change="handleChange"
              >
                <a-icon
                  type="cloud-upload"
                  style="font-size: 48px; margin: 16px 0; color: #d9d9d9"
                ></a-icon>
                <div style="color: #d9d9d9">点击或拖拽到本区域上传</div>
              </a-upload-dragger>
            </a-form-model-item>
            <a-form-model-item
              :wrapper-col="{ span: 20, offset: 4 }"
              style="margin-bottom: 0"
            >
              <a-space size="middle">
                <a-button :loading="loading"  @click="ok">
                  上传
                </a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </template>
      </a-step>
      <a-step title="导入数据">
        <a-icon slot="icon" type="loading" v-if="currentStep === 1" />
        <template slot="description" v-if="currentStep === 1">
          数据导入中...
        </template>
      </a-step>
      <a-step title="导入结果">
        <template slot="description" v-if="currentStep === 2">
          <a-result v-if="displayResult" title="文件导入失败" status="error">
            <template #extra>
              <a-space>
                <a-button type="primary" @click="reload">重新上传</a-button>
                <a-button @click="cancel">取消</a-button>
              </a-space>
            </template>
          </a-result>
          <template v-else>
            <a-table
              class="custom-table scroll-hidden-table"
              :columns="columns"
              :data-source="dataSource"
              :scroll="{ x: scrollX }"
              style="margin-top: 16px"
              row-key="id"
            >
              <template slot="success" slot-scope="text">
                <boolean-tag :value="text" true-name="成功" false-name="失败"></boolean-tag>
              </template>
            </a-table>
            <a-button @click="$emit('ok')" type="primary">确认</a-button>
          </template>
        </template>
      </a-step>
    </a-steps>
  </div>
</template>

<script>
import {
  getTerminalDeviceImportTemplate,
  importTerminalDevice
} from '@/api/terminal-device'
import BooleanTag from '@/components/tag/BooleanTag'

export default {
  name: 'FileImportForm',
  components: {
    BooleanTag
  },
  props: {
    labelCol: {
      type: Object
    },
    wrapperCol: {
      type: Object
    }
  },
  data () {
    return {
      currentStep: 0,
      dataSource: [],
      steps: [
        { title: '文件上传', key: 'upload' },
        { title: '导入数据', key: 'import' },
        { title: '导入结果', key: 'result' }
      ],
      filename: '终端设备模板.xlsx',
      form: {
        fileList: []
      },
      rules: {
        fileList: [
          {
            message: '请上传 Excel 文件',
            required: true,
            trigger: 'change'
          }
        ]
      },
      loading: false,
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 150,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 160,
          scopedSlots: {
            customRender: 'monitorAddress'
          }
        },
        {
          dataIndex: 'success',
          width: 80,
          title: '状态',
          scopedSlots: {
            customRender: 'success'
          }
        },
        {
          dataIndex: 'message',
          title: '描述',
          width: 240,
          ellipsis: true,
          scopedSlots: {
            customRender: 'message'
          }
        }
      ],
      displayResult: false
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  methods: {
    click () {
      getTerminalDeviceImportTemplate().then(res => {
        const blob = new Blob([res], { type: res.type })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = this.filename
        a.click()
        window.URL.revokeObjectURL(url)
      })
    },
    beforeUpload (file) {
      // this.form.fileList.push(file)
      return false
    },
    reset () {
      this.form = {
        fileList: []
      }
      this.$refs.form.resetFields()
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('changeImportStatus', false)
          this.currentStep++
          const formData = new FormData()
          formData.append('file', ...this.form.fileList)
          importTerminalDevice(formData)
            .then(res => {
              this.displayResult = false
              this.dataSource = res.data.data
            })
            .catch(() => {
              this.displayResult = true
              this.$emit('changeImportStatus', true)
            })
            .finally(() => {
              this.currentStep++
              this.$emit('changeImportStatus', true)
            })
        }
      })
    },
    cancel () {
      this.$emit('cancel')
    },
    reload () {
      this.form = {
        fileList: []
      }
      this.currentStep = 0
    },
    handleChange (v) {
      if (v.file.status === 'removed') {
        const index = this.form.fileList.findIndex(item => item.uid === v.file.uid)
        this.form.fileList.splice(index, 1)
      } else {
        this.form.fileList.push(v.file)
      }
    }
  }
}
</script>
<style lang="less">
.file-import-drawer {
  .ant-steps-vertical .ant-steps-item-content {
    min-height: 48px;
  }
  .ant-result-icon {
    i {
      font-size: 48px;
    }
  }
  .ant-result-title {
    font-size: 16px;
  }
}
</style>
