<template>
  <div class="terminal-card-container">
      <a-space :size="16">
        <source-icon source-type="terminal" style="font-size: 24px; padding: 10px; background: #e2ebff; border-radius: 6px;"></source-icon>
        <div class="header">
          <a class="title" style="cursor: default;">{{ detail.name }}</a>
        </div>
      </a-space>
      <div
        @click="$emit('selectGroup', {group_id: detail.id})"
        style="float: right; font-size: 24px; line-height: 20px; padding: 8px; margin-top: 4px; color: #fff; background: #1890ff; border-radius: 6px; cursor: pointer;"
      >
        {{ total }}
      </div>

      <div style="height: 1px; background: #e5e5e5; margin: 9px 0 18px; clear: both;"></div>

      <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 14px;">
        <div
          v-for="(v, k) in detail.value"
          :key="k"
          :style="{
            color: statusColors[k][0],
            borderRadius: '6px',
            background: statusColors[k][1],
            textAlign: 'center',
            padding: '10px 0 8px',
            border: `1px solid ${statusColors[k][0]}50`
          }">
          <div style="font-size: 13px;">{{ $t(`source_status.${k}`) }}</div>
          <div @click="$emit('selectGroup', {group_id: detail.id, status: k})"  style="font-weight: 500; font-size: 18px; margin-top:0px; cursor: pointer;">{{ v }}</div>
        </div>
      </div>
  </div>
</template>

<script>
import { statusColors } from '@/utils/const'
import SourceIcon from '@/components/icon/SourceIcon'

export default {
  name: 'TerminalCard',
  props: {
    detail: {
      type: Object
    }
  },
  components: {
    SourceIcon
  },
  data () {
    return {
      statusColors
    }
  },
  computed: {
    total () {
      let n = 0
      for (const key in this.detail.value) {
        if (Object.hasOwnProperty.call(this.detail.value, key)) {
          const value = this.detail.value[key] * 1
          n += value
        }
      }
      return n
    }
  }
}
</script>

<style lang="less">
.terminal-card-container {
  height: 250px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px 16px 0;
  cursor: default;

  .header {
    position: relative;
    height: 44px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 34px;
    box-sizing: border-box;

    &::before {
      content: '群组';
      position: absolute;
      bottom: 2px;
      left: 0px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      font-size: 11px;
      line-height: 12px;
    }
  }
}
</style>
