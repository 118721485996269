<template>
  <div class="terminal-container">
    <reload-button
      v-show="activeTab === 'overview'"
      @click="overviewRefresh"
      :show-text="false"
      style="position: absolute; right: 16px; top: 13px; z-index: 999"
    ></reload-button>
    <a-tabs v-model="activeTab" :animated="false">
      <a-tab-pane key="overview" tab="总览">
        <div class="module-container">
          <div class="module-header" style="margin-bottom: 24px">分组概览</div>
          <a-row :gutter="16">
            <a-col
              v-for="data in dataSource"
              :key="data.name"
              :md="12"
              :lg="8"
              :xxl="6"
              style="margin-bottom: 16px"
            >
              <terminal-card
                :detail="data"
                @selectGroup="selectGroup"
              ></terminal-card>
            </a-col>
          </a-row>
        </div>
      </a-tab-pane>
      <a-tab-pane key="list" tab="列表详情">
        <a-card :bordered="false">
          <terminal-table
            :refresh="refresh"
            :tools-list="['setting', 'reload', 'search', 'leftTool', 'export']"
            :fetchParams="fetchParams"
          ></terminal-table>
        </a-card>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { getTerminalDeviceGroupCount } from '@/api/terminal-device'
import ReloadButton from '@/components/button/ReloadButton.vue'
import TerminalTable from './modules/TerminalTable.vue'
import TerminalCard from './modules/TerminalCard.vue'

export default {
  name: 'Terminal',
  components: {
    ReloadButton,
    TerminalCard,
    TerminalTable
  },
  inject: ['reload'],
  data () {
    return {
      refresh: false,
      activeTab: 'overview',
      fetchParams: {},
      dataSource: [],
      selectedGroupName: {}
    }
  },
  mounted () {
    getTerminalDeviceGroupCount().then(res => {
      this.dataSource = res.data.data
    })
  },
  methods: {
    selectGroup (params) {
      this.fetchParams = Object.assign({}, {}, params)
      this.activeTab = 'list'
    },
    overviewRefresh () {
      this.reload()
    }
  }
}
</script>

<style lang="less">
.terminal-container {
  padding: 0 6px;
  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px;
    min-height: 380px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  }
}
</style>
