<template>
  <a-form-model ref="form" :label-col="labelCol" :model="form" :rules="rules">
    <a-form-model-item label="名称" prop="name" :wrapper-col="wrapperCol">
      <a-input v-model="form.name" :max-length="128"></a-input>
    </a-form-model-item>
    <a-form-model-item prop="groups" :wrapper-col="wrapperCol">
      <group-select v-model="form.groups" source-type="terminal_device"></group-select>
      <question-icon
        slot="label"
        title="所属群组"
        description="用于分组查询及告警配置"
      ></question-icon>
    </a-form-model-item>
    <a-form-model-item label="设备类型" prop="deviceType" :wrapper-col="wrapperCol">
      <a-select v-model="form.deviceType" :options="options"></a-select>
    </a-form-model-item>
    <a-form-model-item label="监控地址" prop="monitorAddress" :wrapper-col="wrapperCol">
      <a-input v-model="form.monitorAddress" :max-length="128"></a-input>
    </a-form-model-item>
    <a-form-model-item label="代理" prop="proxyId" :wrapper-col="wrapperCol">
      <a-select v-model="form.proxyId" :options="proxies"></a-select>
    </a-form-model-item>
    <a-form-model-item label="监控频率" prop="delay" :wrapper-col="wrapperCol">
      <a-input v-model="form.delay" :min="1" suffix="秒" type="number"></a-input>
    </a-form-model-item>
    <a-form-model-item label="位置" prop="location" :wrapper-col="wrapperCol">
      <a-input v-model="form.location" :max-length="128"></a-input>
    </a-form-model-item>
    <a-form-model-item
      :wrapper-col="{ span: 20, offset: 4 }"
      style="margin-bottom: 0"
    >
      <a-space size="middle">
        <a-button :loading="loading" type="primary" @click="ok">
          确定
        </a-button>
        <a-button @click="cancel">取消</a-button>
      </a-space>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { getMonitorProxyList } from '@/api/monitor'
import {
  updateTerminalDevice,
  createTerminalDevice
} from '@/api/terminal-device'
import { terminalDeviceTypes } from '@/utils/const'
import QuestionIcon from '@/components/icon/QuestionIcon'
import GroupSelect from '@/components/select/GroupSelect.vue'

export default {
  name: 'InputForm',
  components: {
    GroupSelect,
    QuestionIcon
  },
  props: {
    detail: {
      type: Object
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    isClone: {
      type: Boolean
    },
    labelCol: {
      type: Object
    },
    wrapperCol: {
      type: Object
    }
  },
  data () {
    return {
      form: this.isUpdate ? {
        id: this.detail.id,
        name: this.detail.name,
        groups: this.detail.groups,
        deviceType: this.detail.device_type,
        monitorAddress: this.detail.monitor_address,
        proxyId: this.detail.proxy_id,
        delay: this.detail.delay,
        location: this.detail.location
      } : {
        id: '',
        name: '',
        groups: undefined,
        deviceType: 'unknown',
        monitorAddress: '',
        proxyId: '',
        delay: 60,
        location: ''
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        deviceType: [
          {
            message: '请选择设备类型',
            required: true,
            trigger: 'change'
          }
        ],
        monitorAddress: [
          {
            message: '请输入 IP 地址',
            required: true,
            trigger: 'blur'
          }
        ],
        delay: [
          {
            message: '请输入监控频率',
            required: true,
            trigger: 'blur'
          }
        ],
        groups: [
          {
            message: '请选择所属群组',
            required: true,
            trigger: 'select'
          }
        ]
      },
      options: terminalDeviceTypes.map(value => {
        return {
          title: this.$t(`terminal_device_type.${value}`),
          value
        }
      }),
      proxies: [],
      loading: false
    }
  },
  mounted () {
    getMonitorProxyList().then(res => {
      this.proxies = res.data.data.map(item => {
        return {
          title: item.name,
          value: item.id
        }
      })
    })
  },
  methods: {
    reset () {
      this.form = {
        id: '',
        name: '',
        groups: undefined,
        deviceType: 'unknown',
        monitorAddress: '',
        proxyId: '',
        delay: 60,
        location: ''
      }
      this.$refs.form.resetFields()
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            device_type: this.form.deviceType,
            monitor_address: this.form.monitorAddress,
            delay: this.form.delay,
            location: this.form.location
          }
          if (this.form.groups) {
            params.group_ids = this.form.groups.map(group => group.id)
          }
          if (this.form.proxyId) params.proxy_id = this.form.proxyId

          this.loading = true
          if (this.isUpdate && !this.isClone) {
            updateTerminalDevice(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.reset()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            createTerminalDevice(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.reset()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
    cancel () {
      this.$emit('cancel')
      this.reset()
    }
  }
}
</script>
