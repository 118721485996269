<template>
  <a-drawer
    :body-style="isUpdate ? {} : { paddingTop: '8px' }"
    :destroy-on-close="true"
    :title="title"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <input-form
      v-if="isUpdate"
      :detail="detail"
      :is-update="isUpdate"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :isClone="isClone"
      @ok="ok"
      @cancel="closeDrawer"
    ></input-form>
    <a-tabs v-else v-model="activeKey">
      <a-tab-pane key="manual" tab="手动创建">
        <input-form
          :detail="detail"
          :is-update="isUpdate"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          @ok="ok"
          @cancel="closeDrawer"
        ></input-form>
      </a-tab-pane>
      <a-tab-pane key="import" tab="文件导入">
        <file-import-form
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          @ok="ok"
          @changeImportStatus="changeImportStatus"
          @cancel="closeDrawer"
        ></file-import-form>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import { getTerminalDevice } from '@/api/terminal-device'
import FileImportForm from './modules/FileImportForm.vue'
import InputForm from './modules/InputForm.vue'

export default {
  name: 'InputDrawer',
  components: {
    FileImportForm,
    InputForm
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      isClone: false,
      labelCol: {
        span: 4,
        style: {
          textAlign: 'left'
        }
      },
      wrapperCol: {
        span: 20
      },
      detail: {},
      activeKey: 'manual',
      importDone: true
    }
  },
  computed: {
    title () {
      if (this.isClone) {
        return '终端设备克隆'
      } else if (this.isUpdate) {
        return '终端设备编辑'
      } else return ''
    }
  },
  methods: {
    show (id, isClone = false) {
      if (id) {
        this.isUpdate = true
        this.isClone = isClone
        getTerminalDevice(id).then(res => {
          this.detail = res.data
        }).finally(() => {
          this.visible = true
        })
      } else {
        this.isClone = isClone
        this.isUpdate = false
        this.visible = true
      }
    },
    reset () {
      this.isClone = false
      this.isUpdate = false
      this.detail = {}
      this.activeKey = 'manual'
      this.visible = false
    },
    closeDrawer () {
      if (this.activeKey === 'import') {
        if (this.importDone) {
          this.reset()
        } else {
          this.$message.warn('请等待导入完成...')
        }
      } else this.reset()
    },
    ok () {
      this.$emit('ok')
      this.activeKey = 'manual'
      this.visible = false
    },
    changeImportStatus (v) {
      this.importDone = v
    }
  }
}
</script>
