var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"terminal-card-container"},[_c('a-space',{attrs:{"size":16}},[_c('source-icon',{staticStyle:{"font-size":"24px","padding":"10px","background":"#e2ebff","border-radius":"6px"},attrs:{"source-type":"terminal"}}),_c('div',{staticClass:"header"},[_c('a',{staticClass:"title",staticStyle:{"cursor":"default"}},[_vm._v(_vm._s(_vm.detail.name))])])],1),_c('div',{staticStyle:{"float":"right","font-size":"24px","line-height":"20px","padding":"8px","margin-top":"4px","color":"#fff","background":"#1890ff","border-radius":"6px","cursor":"pointer"},on:{"click":function($event){return _vm.$emit('selectGroup', {group_id: _vm.detail.id})}}},[_vm._v(" "+_vm._s(_vm.total)+" ")]),_c('div',{staticStyle:{"height":"1px","background":"#e5e5e5","margin":"9px 0 18px","clear":"both"}}),_c('div',{staticStyle:{"display":"grid","grid-template-columns":"1fr 1fr","grid-gap":"14px"}},_vm._l((_vm.detail.value),function(v,k){return _c('div',{key:k,style:({
          color: _vm.statusColors[k][0],
          borderRadius: '6px',
          background: _vm.statusColors[k][1],
          textAlign: 'center',
          padding: '10px 0 8px',
          border: `1px solid ${_vm.statusColors[k][0]}50`
        })},[_c('div',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.$t(`source_status.${k}`)))]),_c('div',{staticStyle:{"font-weight":"500","font-size":"18px","margin-top":"0px","cursor":"pointer"},on:{"click":function($event){return _vm.$emit('selectGroup', {group_id: _vm.detail.id, status: k})}}},[_vm._v(_vm._s(v))])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }